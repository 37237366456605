
import { Coupon, CouponFilter } from '@/models/Provider';
import { api } from '@/services/Api';
import { computed, defineComponent, inject, onBeforeUnmount, onMounted, ref } from 'vue';
import swal from 'sweetalert2';
import { generalStore } from '@/store';
import { useRouter } from 'vue-router';
import Q from 'q';
import { CouponLimitationType } from '@/models/Enums';

export default defineComponent({
    props: {
        businessClientId: {
            type: Number,
            required: false
        },
    },
    async setup(props) {
        const couponLimitationType = CouponLimitationType;
        const sortAsc = ref(true);
        const sortParam = ref('Id');
        const filter = ref(new CouponFilter());
        filter.value = generalStore.getters.getCouponFilter;
        const coupons = ref(new Array<Coupon>());
        const router = useRouter();
        const busy = ref(false);
        const loaded = ref(false);
        const hasNext = ref(false);
        const total = ref(0);
        const totalFiltered = ref(0);
        const emitter: any = inject('emitter');
        if (props.businessClientId) {
            filter.value.clubId = props.businessClientId.toString();
            filter.value.fromBusinessClient = true;
        }
        const hasFilter = computed<boolean>(() => {
            if (props.businessClientId)
                return Object.entries(filter.value)
                    .filter(x => !['asc', 'pageIndex', 'ordeBy', 'keyword', 'fromBusinessClient', 'clubId'].includes(x[0]))
                    .map(x => Boolean(x[1]))
                    .reduce((prev, curr) => prev || curr);
            else
                return Object.entries(filter.value)
                    .filter(x => !['asc', 'pageIndex', 'ordeBy', 'keyword', 'fromBusinessClient'].includes(x[0]))
                    .map(x => Boolean(x[1]))
                    .reduce((prev, curr) => prev || curr);
        });

        const ShowFilter = () => {
            generalStore.commit('saveCouponFilter', filter.value);
            if (props.businessClientId)
                router.push({name: 'modal-coupon-filter', params: {businessClientId: props.businessClientId}});
            else
                router.push({ name: 'modal-coupon-filter' });
        };

        function getCurrencySymbol(symbol: string) {
            return symbol ?? '%';
        }

        function getDiscount(c: Coupon) {
            if (c.discountAmount) {
                return c.discountAmount.toString() + ' ' + getCurrencySymbol(c?.currency?.symbol);
            }
        }

        const GetCouponsApiCall = async () => {
            swal.fire({
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    swal.showLoading();
                },
                showConfirmButton: false
            });
            busy.value = true;
            if (filter.value.couponStatus != null) filter.value.couponStatus = Boolean(filter.value.couponStatus);
            const apiResult = await api.getCoupons(filter.value);
            await Q.delay(400);
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            hasNext.value = apiResult.data?.hasNext || false;
            if (filter.value.pageIndex == 0) {
                total.value = apiResult.data?.total || 0;
                totalFiltered.value = apiResult.data?.rows.length || 0;
            }
            if (apiResult.data && coupons.value && coupons.value.length) {
                coupons.value = [...coupons.value, ...apiResult.data.rows];
            } else {
                coupons.value = apiResult.data?.rows || [];
            }
            filter.value.pageIndex++;
            busy.value = false;
            swal.close();
        };

        async function getCoupons() {
            coupons.value = [];
            filter.value.pageIndex = 0;
            await GetCouponsApiCall();
        }

        function ListenerGetCoupons() {
            if (hasNext.value && !busy.value) {
                GetCouponsApiCall();
            }
        }

        async function showAll() {
            generalStore.commit('saveCouponFilter', new CouponFilter());
            filter.value = new CouponFilter();
            coupons.value = [];
            if (props.businessClientId) {
                filter.value.clubId = props.businessClientId.toString();
                filter.value.fromBusinessClient = true;
            }
            await GetCouponsApiCall();
        }

        async function sortBy(sotrBy: string) {
            if (filter.value.orderBy != sotrBy) {
                filter.value.asc = true;
            } else {
                filter.value.asc = !filter.value.asc;
            }
            filter.value.orderBy = sotrBy;
            filter.value.pageIndex = 0;
            coupons.value = [];
            await GetCouponsApiCall();
        }

        const getArrowClass = (sort: string) => {
            if (sort == sortParam.value) {
                if (sortAsc.value) {
                    return 'top-sort-arrow';
                } else {
                    return 'bottom-sort-arrow';
                }
            }
            return 'standart-sort-arrow';
        };

        function onBeforeUnMountedCall() {
            emitter.off('isBottom', ListenerGetCoupons);
        }
        const onMountedCall = async () => {
            emitter.on('isBottom', ListenerGetCoupons);
            filter.value.pageIndex = 0;
            await GetCouponsApiCall();
            loaded.value = true;
        };

        function routeToProduct(id: string) {
            router.push({ name: 'product', params: { id: id, tab: 1 } });
        }

        function routeToClub(id: number) {
            router.push({ name: 'businessClientView', params: { id: id, page: 1 } });
        }

        function routeToCoupon(id: string) {
            router.push({ name: 'coupon', params: { id: id } });
        }

        function newCoupon() {
            if (props.businessClientId)
                router.push({ name: 'coupon-new', params: { businessClientId: props.businessClientId } });
            else
                router.push({ name: 'coupon-new' });
        }

        function editCoupon(id: string) {
            if (props.businessClientId)
                router.push({ name: 'coupon-edit', params: { couponId: id, businessClientId: props.businessClientId } });
            else
                router.push({ name: 'coupon-edit', params: { couponId: id } });
        }

        onMounted(onMountedCall);
        onBeforeUnmount(onBeforeUnMountedCall);

        return {
            newCoupon,
            filter,
            coupons,
            getArrowClass,
            total,
            getDiscount,
            getCoupons,
            ShowFilter,
            sortBy,
            routeToProduct,
            routeToClub,
            routeToCoupon,
            showAll,
            hasFilter,
            editCoupon,
            totalFiltered,
            couponLimitationType
        };
    }
});
